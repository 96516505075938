.disconnection {
  .MuiDialog-paperWidthSm {
    width: 1000px;
    max-width: 100%;
  }

  .MuiDialog-paper {
    margin: 8px;
  }
}
