.landmark {
  width: 408px;
  height: 50%;

  .MuiBackdrop-root {
    display: none;
  }

  .MuiDrawer-paperAnchorLeft {
    width: inherit;
    background-color: rgba(255, 255, 255, 0.9);
  }

  .MuiDrawer-paperAnchorBottom {
    height: 50%;
  }
}
